const createHeroImageWithSrcset = (imageData) => {
	const heroImg = document.createElement('img'),
		imageExtension = imageData.src.split('.').pop(),
		imageUrl = imageData.src.split(`.${imageExtension}`),
		retinaImage = `${imageUrl[0]}@2x.${imageExtension}`,
		srcSet = `${imageData.src}, ${retinaImage} 2x`;

	heroImg.setAttribute('src', imageData.src);
	heroImg.setAttribute('srcSet', srcSet);
	heroImg.setAttribute('height', imageData.height);
	heroImg.setAttribute('width', imageData.width);
	heroImg.setAttribute('alt', imageData.alt);

	return heroImg;
};

export default createHeroImageWithSrcset;
