const dayTime = () => {
	const d = new Date();
	const n = d.getHours();
	//console.log(n);
	if (n >= 18 || n <= 5) {
		document.body.classList.add('night');
		return 'night';
	}
	document.body.classList.add('day');
	return 'day';
};

export default dayTime;
