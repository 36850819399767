/* eslint-disable @wordpress/dependency-group */
// import $ from 'jquery';

import dayTime from './inc/day-time';
import createHeroImageWithSrcset from './inc/createImage';

const setImage = () => {
	const heroContainer = document.querySelector('.hero-image'),
		heroContainerDataAttribute = JSON.parse(
			heroContainer.getAttribute('data-imgs')
		),
		timeOfDay = dayTime(),
		imageData = heroContainerDataAttribute[timeOfDay],
		heroImg = createHeroImageWithSrcset(imageData);
	document.querySelector('[rel="hero-image-container"]').appendChild(heroImg);
};

const setBoxLink = () => {
	const heroBox = document.querySelectorAll('.hero-box');
	heroBox.forEach(function(elem) {
		elem.addEventListener(
			'click',
			() => (window.location = elem.querySelector('a').href)
		);
	});
};

setImage();
setBoxLink();
